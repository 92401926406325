@import '_vars';

.container{
  position:relative;padding-left:25px;padding-right:25px;
  @media (min-width: $screen-m){max-width:1300px;padding-left:50px;padding-right:50px;}
  &::before,&::after{content:'\0020';display:table;clear:both;}
}

.nav{
  font-size:0;box-shadow:0 0 20px 1px rgba(50, 50, 50, 0.1);
  & .container{padding-top:15px;padding-bottom:15px;}
  &-left{float:left;}
  &-right{
    float:right;
    @media (min-width: $screen-m){margin-top:20px;}
  }
  // shared
  &-item{display:inline-block;}
  // left
  &-logo img,&-brand{vertical-align:middle;}
  &-logo{
    position:relative;padding-right:10px;z-index:10005;
    & a{display:block;}
    & img{
      height:55px;margin-right:30px;
      @media (min-width: $screen-m){height:60px;}
    }
  }
  &-brand{
    display:inline-block;font-size:18px;font-weight:700;
    @media (min-width: $screen-m){font-size:22px;}
  }
  // right
  &-menu{
    display:none;position:fixed;top:0;bottom:0;left:0;right:0;padding-top:100px;background-color:rgba(255, 255, 255, 0.8);z-index:10000;
    @media (min-width: $screen-m){display:block;position:relative;padding-top:0;background-color:none;}
    &.open{display:block;}
    &-trigger{
      position:relative;margin-top:8px;padding:3px;z-index:10005;
      @media (min-width: $screen-m){display:none;}
      & span{display:block;margin:5px 0;height:3px;width:26px;background-color:$red1;border-radius:1px;}
    }
    & li{
      display:block;margin:20px 0;font-size:24px;text-align:center;
      @media (min-width: $screen-m){display:inline-block;margin:0;font-size:18px;text-align:left;}
      &.active a{color:$red1;}
    }
    & a{
      padding:8px 15px;color:#555;border:1px solid transparent;transition-duration:100ms;
      &:hover,&:active{color:#000;box-shadow:0 0 20px 1px rgba(130, 130, 130, 0.15);border-radius:$bdr1;border-color:#f4f5f7;}
    }
    & span{font-weight:600;margin-bottom:5px;}
    & .mobile{
      @media (min-width: $screen-m){display:none;}
    }
  }
}

/*
    sections
*/
// sub sections
.section{
  padding:20px 0;
  @media (min-width:$screen-m){padding:25px 0;}
}
.subsec{
  padding:15px 0;
  @media (min-width:$screen-m){padding:20px 0;}
}
